import { API, Cache } from 'aws-amplify';

// const config = {
//   itemMaxSize: 3000, // 3000 bytes
//   storage: window.sessionStorage, // switch to sessionStorage
//   // ...
// };
// const newCache = Cache.createInstance(config);
// Please provide a new keyPrefix which is the identifier of Cache.

const getFiscal = (reportQuery, timeFrame, accountName, accountId) => {
  const athenaData = {
    body: {
      query: reportQuery,
      // client_billable_calls_by_account_and_month,
      // monthly_summary_by_client_and_month, geo_distribution_by_client,
      // segment_distribution_by_client, user_activity_by_client_and_month
      // vendor_billable_calls_and_cost
      account_name: accountName,
      account_id: accountId,
      month: timeFrame,
    },
  };

  return API.post('AppHubAPI', 'activity/reports', athenaData)
    .then(async (response) => {
      Cache.setItem('key', response); // TODO: WIP
      return response;
    });
};

export default getFiscal;
