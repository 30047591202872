<template>
  <div>
    <v-card v-if="activeCommitmentStr != 'None'">
      <v-container class="text-center">
        <h2 class="title font-weight-light text-left">Commitment Progress</h2>
        <p class="caption text-left mb-4">
          Your cumulative monthly view of activity this commitment cycle
        </p>

        <v-container class="px-0">
          <v-row>
            <!-- <v-col>
              <v-card outlined height="100%">
                <small class="caption grey--text">Calls</small>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      <v-icon small color="blue darken-2">mdi-information-outline</v-icon>
                    </span>
                  </template>
                  <span>Total API calls this commitment period.</span>
                </v-tooltip>

                <v-container>
                  <v-row>
                    <v-col>
                      <div class="display-1 font-weight-light">{{ profileCalls | numFormat }}</div>
                      <div class="caption font-weight-light">Profile</div>
                    </v-col>
                    <v-col v-if="appetiteCalls">
                      <div class="display-1 font-weight-light">
                        {{ appetiteCalls | numFormat }}
                      </div>
                      <div class="caption font-weight-light">Appetite</div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-col> -->

            <v-col>
              <v-card outlined height="100%">
                <small class="caption grey--text">Billable Calls</small>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      v-bind="attrs"
                      v-on="on"
                    ><v-icon small color="blue darken-2">mdi-information-outline</v-icon></span>
                  </template>
                  <span>API call that resulted in a found business.</span>
                </v-tooltip>

                <v-container>
                  <v-row>
                    <v-col>
                      <div class="display-1 font-weight-light">
                        {{ profileHits | numFormat }}
                      </div>
                      <div class="caption font-weight-light">Profile</div>
                    </v-col>
                    <v-col v-if="appetiteHits">
                      <div class="display-1 font-weight-light">
                        {{ appetiteHits | numFormat }}
                      </div>
                      <div class="caption font-weight-light">Appetite</div>
                    </v-col>
                    <v-col v-if="propertyHits">
                      <div class="display-1 font-weight-light">
                        {{ propertyHits | numFormat }}
                      </div>
                      <div class="caption font-weight-light">Property</div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-col>

            <v-col>
              <v-card outlined height="100%">
                <small class="caption grey--text">Commitment</small>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      v-bind="attrs"
                      v-on="on"
                    ><v-icon small color="blue darken-2">mdi-information-outline</v-icon></span>
                  </template>
                  <span>Your commitment for this period.</span>
                </v-tooltip>

                <v-container>
                  <v-row>
                    <v-col>
                      <div class="display-1 font-weight-light" v-if="profileCommitment">
                        {{ profileCommitment | numFormat }}
                      </div>
                      <div class="caption font-weight-light" v-if="profileCommitment">
                        Profile
                      </div>

                      <div
                        class="display-1 font-weight-light"
                        v-if="!profileCommitment && !appetiteCommitment"
                      >
                        None
                      </div>

                      <!-- <div class="caption font-weight-light" v-if="profileCommitment"> -->
                        <!-- <strong class="font-weight-bold">
                          {{ (profileHits/profileCommitment)*100 | numFormat }}%
                        </strong> Usage -->
                        <!-- <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">
                              <v-icon small color="blue darken-2">mdi-information-outline</v-icon>
                            </span>
                          </template>
                          <span>Usage = Billable Hits / Commitement</span>
                        </v-tooltip> -->
                      <!-- </div> -->
                    </v-col>
                    <v-col v-if="appetiteCommitment">
                      <div class="display-1 font-weight-light">
                        {{ appetiteCommitment | numFormat }}
                      </div>
                      <div class="caption font-weight-light">
                        Appetite
                      </div>

                      <!-- <div class="caption font-weight-light">
                        <strong class="font-weight-bold">
                          {{ (appetiteHits/appetiteCommitment)*100 | numFormat }}%
                        </strong> Usage
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">
                              <v-icon small color="blue darken-2">mdi-information-outline</v-icon>
                            </span>
                          </template>
                          <span>Usage = Billable Hits / Commitement</span>
                        </v-tooltip>
                      </div> -->
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-col>

            <v-col>
              <v-card outlined height="100%">
                <small class="caption grey--text">Cycle Period</small>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      v-bind="attrs"
                      v-on="on"
                    ><v-icon small color="blue darken-2">mdi-information-outline</v-icon></span>
                  </template>
                  <span>The period of your contract cycle.</span>
                </v-tooltip>
                <div class="font-weight-light my-3">
                  <strong>{{ startDate | moment("MMMM Do, YYYY") }}</strong>
                  <div class="caption font-weight-light">- to -</div>
                  <strong>{{ endDate | moment("MMMM Do, YYYY") }}</strong>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-container>

        <v-progress-linear
          v-if="profileCommitment"
          rounded
          stream
          buffer-value="0"
          height="10"
          :value="(profileHits/profileCommitment)*100"
        ></v-progress-linear>

        <h5 class="caption" v-if="(profileHits/profileCommitment)*100 <= 99">
          {{ (profileHits/profileCommitment)*100 | numFormat }}%
        </h5>
        <h5 class="caption" v-if="(profileHits/profileCommitment)*100 >= 100">
          100%
        </h5>

        <div class="caption" v-if="profileCommitment && (profileHits/profileCommitment)*100 < 100">
          <h5>Keep going! 🙌</h5>
          <p>You haven't reached your commitment yet, but it's on the horizon!</p>
        </div>

        <div
          class="caption"
          v-if="profileCommitment
            && (profileHits/profileCommitment)*100 >= 100
            && (profileHits/profileCommitment)*100 < 200"
        >
          <h5>🎉 Congratulations! 🎉</h5>
          <p>You've reached your commitment, keep going to reach all-star status!</p>
        </div>

        <div class="caption" v-if="profileCommitment && (profileHits/profileCommitment)*100 >= 200">
          <h5>🌟 Congratulations you're an all-star! 🌟</h5>
          <p>You've not only reached your commitment but reached all-star status!</p>
        </div>
      </v-container>

      <apexchart
        v-if="!loading"
        height="350"
        :options="chartOptions"
        :series="series"
      ></apexchart>

      <v-progress-linear
        v-if="loading"
        class="loading-indicator"
        indeterminate
        color="primary"
      ></v-progress-linear>

      <v-card-text class="pt-0">
        <v-divider class="my-2"></v-divider>
        <v-icon
        class="mr-2"
        small
        >
        mdi-clock
        </v-icon>
        <span class="caption grey--text font-weight-light">
          Showing activity as of {{ Date().toLocaleString() | moment("dddd, MMMM Do YYYY") }}
        </span>
      </v-card-text>
    </v-card>
    <v-snackbar v-model="error">
      <v-icon>mdi-alert-circle-outline</v-icon>
      {{ message }}
    </v-snackbar>
  </div>
</template>

<script>
import getFiscalAthena from '@/services/getFiscalAthena';
import getCommitment from '@/services/getCommitment';
import moment from 'moment';

export default {
  name: 'CommitmentProgress',
  props: {
    client: {
      type: String,
    },
    accountId: {
      type: String,
    },
  },
  data() {
    return {
      productTypes: [],
      profileBillableCalls: [],
      propertyBillableCalls: [],
      appetiteBillableCalls: [],
      monthlyCalls: [],
      orgName: this.client,
      startDate: '20210101',
      endDate: '20210201',
      activeCommitmentStart: '2020-01',
      fiscal: [],
      error: false,
      message: '',
      hits: 0,
      profileHits: 0,
      appetiteHits: 0,
      propertyHits: 0,
      profileCalls: 0,
      appetiteCalls: 0,
      calls: 0,
      commitment: 0,
      profileCommitment: 0,
      appetiteCommitment: 0,
      dateValues: [],
      timeFrame: 'today',
      activeCommitment: '20210101',
      activeCommitmentStr: '',
      loading: false,
      series: [
        {
          name: 'Profile',
          data: [],
        },
        {
          name: 'Appetite',
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          toolbar: {
            show: false,
          },
          height: 350,
          type: 'line',
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: true,
          dropShadow: {
            enabled: true,
          },
        },
        stroke: {
          curve: 'smooth',
          width: 7,
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'],
            opacity: 0.5,
          },
        },
        xaxis: {
          categories: [],
        },
        annotations: {
          yaxis: [{
            y: 0,
            borderColor: '#999',
            label: {
              borderColor: '#999',
              style: {
                color: '#fff',
                background: '#999',
              },
              text: 'Profile Commitment',
            },
          },
          ],
          xaxis: [{
            x: '',
            borderColor: '#000',
            yAxisIndex: 0,
            label: {
              show: true,
              text: 'TODAY',
              style: {
                color: '#fff',
                background: '#f00',
              },
            },
          }],
        },
        noData: {
          text: 'No data to display for this period.',
          align: 'center',
          verticalAlign: 'middle',
          offsetX: 0,
          offsetY: 0,
          style: {
            color: '#333',
            fontSize: '14px',
            fontFamily: 'Libre Franklin, sans-serif',
          },
        },
      },
    };
  },
  methods: {
    getFiscalAthena() {
      // Start Loading
      this.loading = true;
      getCommitment(this.orgName)
        .then((results) => {
          const today = new Date();
          let currentCommitmentStart = 0;
          let currentCommitmentEnd = 0;
          let pCommitment = 0;
          let aCommitment = 0;
          let fromStr = '';
          let toStr = '';
          if (results[this.orgName].commitments) {
            const commitments = results[this.orgName].commitments; // eslint-disable-line
            for (let index = 0; index < commitments.length; index++) { // eslint-disable-line
              const dateFrom = commitments[index].start;
              const dateTo = commitments[index].end;
              pCommitment = commitments[index].services.profile;
              aCommitment = commitments[index].services.appetite;
              const d1 = dateFrom.split('-');
              const d2 = dateTo.split('-');

              const from = new Date(d1[0], parseInt(d1[1], 10) - 1, d1[2]);
              const to = new Date(d2[0], parseInt(d2[1], 10) - 1, d2[2]);
              fromStr = from.toLocaleDateString('en-US');
              toStr = to.toLocaleDateString('en-US');

              // Check if commitment is active
              if (today > from && today < to) {
                currentCommitmentStart = d1[0] + d1[1] + d1[2];
                currentCommitmentEnd = d2[0] + d2[1] + d2[2];
                break;
              }
            }
          }

          // Get data if active commitment is found, else process is complete
          if (currentCommitmentEnd !== 0) {
            this.activeCommitment = currentCommitmentStart; // eslint-disable-line
            this.activeCommitmentStr = fromStr + ' - ' + toStr; // eslint-disable-line
            this.activeCommitmentStart = moment(fromStr).format('YYYY-MM');
            // Cycle
            this.startDate = fromStr;
            this.endDate = toStr;
          } else {
            this.activeCommitmentStr = 'None';
          }

          // Commitments
          // Profile
          this.profileCommitment = pCommitment;
          // results[this.orgName].commitments[0].services.profile;
          this.chartOptions.annotations.yaxis[0].y = pCommitment;
          // results[this.orgName].commitments[0].services.profile; // eslint-disable-line

          // Appetite
          this.appetiteCommitment = aCommitment;
          // results[this.orgName].commitments[0].services.appetite;
          if (aCommitment > 0) {
            this.chartOptions.annotations.yaxis[1] = {
              y: aCommitment, // results[this.orgName].commitments[0].services.appetite,
              borderColor: '#999',
              label: {
                borderColor: '#999',
                style: {
                  color: '#fff',
                  background: '#999',
                },
                text: 'Appetite Commitment',
              },
            };
          }

          this.getData();
        });
    },
    getData() {
      getFiscalAthena('client_billable_calls_by_account_and_month', this.activeCommitmentStart, this.client, this.accountId)
        .then((results) => {
          // Get unique products
          this.productTypes = [...new Set(results.map((item) => item.product))];

          // Group by Product Type
          function groupBy(results, property) { // eslint-disable-line
            return results.reduce(function(memo, x) {  // eslint-disable-line
              if (!memo[x[property]]) { memo[x[property]] = []; }  // eslint-disable-line
              memo[x[property]].push(x);  // eslint-disable-line
              return memo;  // eslint-disable-line
            }, {});  // eslint-disable-line
          }  // eslint-disable-line

          const o = groupBy(results, 'product');

          if (o.profile !== undefined) {
            const profileResult = [...new Set(o.profile.map((item) => parseInt(item.billable_calls, 10) || 0))]; // eslint-disable-line
            let sum = 0;
            this.profileBillableCalls = profileResult.map((v) => sum += v); // eslint-disable-line
            this.profileHits = profileResult.reduce((a, b) => a + b, 0);
          }
          if (o.appetite !== undefined) {
            const appetiteResult = [...new Set(o.appetite.map((item) => parseInt(item.billable_calls, 10) || 0))]; // eslint-disable-line
            let sum = 0;
            this.appetiteBillableCalls = appetiteResult.map((v) => sum += v); // eslint-disable-line
            this.appetiteHits = appetiteResult.reduce((a, b) => a + b, 0);
          }
          if (o.property !== undefined) {
            const propertyResult = [...new Set(o.property.map((item) => parseInt(item.billable_calls, 10) || 0))]; // eslint-disable-line
            let sum = 0;
            this.propertyBillableCalls = propertyResult.map((v) => sum += v); // eslint-disable-line
            this.propertyHits = propertyResult.reduce((a, b) => a + b, 0);
          }

          this.hits = this.profileHits + this.propertyHits + this.appetiteHits;

          this.series = [
            { name: 'Profile', data: this.profileBillableCalls },
            { name: 'Appetite', data: this.appetiteBillableCalls },
            { name: 'Property', data: this.propertyBillableCalls },
          ];

          // TimeFrame
          this.chartOptions.xaxis = { categories: [...new Set(results.map((item) => item.month))] }; // eslint-disable-line

          // Finish Loading
          this.loading = false;
        })
        .catch((error) => {
          console.log(error); // eslint-disable-line
          this.error = true;
          this.message = error.message;
        });
    },
  },
  mounted() {
    this.getFiscalAthena();
  },
};
</script>

<style scoped>
.loading-indicator {
  position: absolute;
  top: 0;
  right: 0;
}
.data-warning {
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
}
</style>
