<template>
  <div>
    <v-card>
      <v-container class="text-center">
        <h2 class="title text-left font-weight-light pt-2">Industry NAICS Segmentation</h2>
        <p class="caption text-left">
          Insights into specific industry segmentation based on the NAICS found on your hits
        </p>

        <v-btn-toggle
          mandatory
          dense
          borderless
          color="primary"
          v-model="timeFrame"
        >
          <v-btn value="today">
            Today
          </v-btn>
          <v-btn value="yesterday">
            Yesterday
          </v-btn>
          <v-btn value="last week">
            Last Week
          </v-btn>
          <v-btn value="last month">
            Last Month
          </v-btn>
          <v-btn value="this month">
            This Month
          </v-btn>
        </v-btn-toggle>
      </v-container>

      <apexchart
        height="350"
        width="100%"
        class="text-left"
        :options="chartOptions"
        :series="series"
      ></apexchart>

      <v-tooltip
        v-if="!series.length"
        top>
        <template v-slot:activator="{ on, attrs }">
          <span
            class="data-warning"
            v-bind="attrs"
            v-on="on"
          ><v-icon>mdi-alert-circle-outline</v-icon></span>
        </template>
        <span>No data to display for this period.</span>
      </v-tooltip>

      <v-progress-linear
        v-if="loading"
        class="loading-indicator"
        indeterminate
        color="primary"
      ></v-progress-linear>

      <v-card-text class="pt-0 text-left">
        <v-divider class="my-2"></v-divider>
        <v-icon
        class="mr-2"
        small
        >
        mdi-clock
        </v-icon>
        <span class="caption grey--text font-weight-light">
          Showing activity for
          {{ date.from | moment("dddd, MMMM Do YYYY") }}
          to {{ date.to | moment("dddd, MMMM Do YYYY") }}
        </span>
      </v-card-text>
    </v-card>
    <v-snackbar v-model="error">
      <v-icon>mdi-alert-circle-outline</v-icon>
      {{ message }}
    </v-snackbar>
  </div>
</template>

<script>
import getActivity from '@/services/getActivity';

export default {
  name: 'IndustrySegments',
  props: {
    client: {
      type: String,
    },
  },
  data() {
    return {
      series: [],
      date: [],
      loading: false,
      timeFrame: 'last month',
      error: false,
      message: '',
      orgName: this.client,
      chartOptions: {
        chart: {
          width: '100%',
          type: 'donut',
        },
        labels: [],
        responsive: [{
          options: {
            chart: {
              width: '100%',
            },
            legend: {
              position: 'bottom',
            },
          },
        }],
        noData: {
          text: 'No data to display for this period.',
          align: 'center',
          verticalAlign: 'middle',
          offsetX: 0,
          offsetY: 0,
          style: {
            color: '#333',
            fontSize: '14px',
            fontFamily: 'Libre Franklin, sans-serif',
          },
        },
      },
    };
  },
  methods: {
    getActivity() {
      this.loading = true;
      getActivity(this.timeFrame, this.orgName)
        .then((results) => {
          this.chartOptions = { labels: [] };
          this.series = [];
          if (results.clients[this.orgName]) {
            this.chartOptions = { labels: Object.keys(results.clients[this.orgName].segment) };
            this.series = Object.values(results.clients[this.orgName].segment);
          }
          this.date = results.date;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error); // eslint-disable-line
          this.error = true;
          this.message = error.message;
        });
    },
  },
  mounted() {
    this.getActivity();
  },
  watch: {
    timeFrame() {
      this.getActivity();
    },
  },
};
</script>

<style scoped>
.loading-indicator {
  position: absolute;
  top: 0;
  right: 0;
}
.data-warning {
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
}
</style>
