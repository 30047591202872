<template>
  <div>
    <v-card>
      <v-container>
        <v-row>
          <v-col>
            <h2 class="title font-weight-light pt-2">User Group Activity</h2>
            <p class="caption">Your business group activities over time</p>
          </v-col>
          <v-col cols="12" md="3" class="text-right">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="date"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateRangeText"
                  prepend-icon="mdi-calendar"
                  append-icon="mdi-menu-down"
                  dense
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date"
                no-title
                scrollable
                range
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="menu = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menu.save(date), getActivity()"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
            <v-btn-toggle
              v-model="toggle_exclusive"
              dense
              borderless
              color="primary"
              class="mb-6"
            >
              <v-btn value="today" v-on:click="setToday">
                Today
              </v-btn>
              <v-btn value="lastWeek" v-on:click="setLastWeek">
                Last Week
              </v-btn>
              <v-btn value="lastMonth" v-on:click="setLastMonth">
                Last Month
              </v-btn>
            </v-btn-toggle>
          </v-col>
        </v-row>
      </v-container>

      <v-progress-linear
        v-if="loading"
        class="loading-indicator"
        indeterminate
        color="primary"
      ></v-progress-linear>

      <apexchart
        height="430"
        v-if="!loading"
        :options="chartOptions"
        :series="series"
      ></apexchart>

      <v-card-text class="pt-0">
        <v-divider class="my-2"></v-divider>
        <v-icon class="mr-2" small>mdi-clock</v-icon>
        <span class="caption grey--text font-weight-light">
          Showing activity for
          {{ date.from | moment("dddd, MMMM Do YYYY") }}
          to {{ date.to | moment("dddd, MMMM Do YYYY") }}
        </span>
      </v-card-text>
    </v-card>
    <v-snackbar v-model="error">
      <v-icon>mdi-alert-circle-outline</v-icon>
      {{ message }}
    </v-snackbar>
  </div>
</template>

<script>
import getActivity from '@/services/getActivity';

export default {
  name: 'UserCalls',
  props: {
    client: {
      type: String,
    },
  },
  data() {
    return {
      date: ['2021-03-05'],
      menu: false,
      modal: false,
      menu2: false,
      // timeFrame: 'this month',
      orgName: this.client,
      toggle_exclusive: 'lastMonth',
      channels: [],
      // date: [],
      error: false,
      message: '',
      loading: false,
      series: [{
        name: 'Total Calls',
        data: [],
      }],
      chartOptions: {
        chart: {
          toolbar: {
            show: false,
          },
          type: 'bar',
          height: 430,
        },
        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              position: 'center',
            },
          },
        },
        dataLabels: {
          enabled: true,
          dropShadow: {
            enabled: true,
          },
          // offsetX: 0,
          // style: {
          //   fontSize: '12px',
          //   colors: ['#000'],
          // },
        },
        stroke: {
          show: true,
          width: 1,
          colors: ['#fff'],
        },
        xaxis: {
          categories: [],
        },
        noData: {
          text: 'No data to display for this period.',
          align: 'center',
          verticalAlign: 'middle',
          offsetX: 0,
          offsetY: 0,
          style: {
            color: '#333',
            fontSize: '14px',
            fontFamily: 'Libre Franklin, sans-serif',
          },
        },
      },
    };
  },
  /* eslint-disable */
  methods: {
    setToday() {
      const today = new Date();
      const dateStr = `${String(today.getFullYear())}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}`;
      this.date = Array.prototype.slice.call([dateStr]);
      this.getActivity();
    },
    setLastWeek() {
      const today = new Date();
      const startingSunday = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay() - 7);
      const endingSunday = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay());
      const startStr = `${String(startingSunday.getFullYear())}-${String(startingSunday.getMonth() + 1).padStart(2, '0')}-${String(startingSunday.getDate()).padStart(2, '0')}`;
      const endStr = `${String(endingSunday.getFullYear())}-${String(endingSunday.getMonth() + 1).padStart(2, '0')}-${String(endingSunday.getDate()).padStart(2, '0')}`;
      this.date = Array.prototype.slice.call([startStr, endStr]);
      this.getActivity();
    },
    setLastMonth() {
      const today = new Date();
      const firstDay = new Date(today.getFullYear(), today.getMonth() - 1, 1);
      const lastDay = new Date(today.getFullYear(), today.getMonth(), 0);
      const startStr = `${String(firstDay.getFullYear())}-${String(firstDay.getMonth() + 1).padStart(2, '0')}-${String(firstDay.getDate()).padStart(2, '0')}`;
      const endStr = `${String(lastDay.getFullYear())}-${String(lastDay.getMonth() + 1).padStart(2, '0')}-${String(lastDay.getDate()).padStart(2, '0')}`;
      this.date = Array.prototype.slice.call([startStr, endStr]);
      this.getActivity();
    },
    getActivity() {
      const dates = this.date;
      // Make sure dates selected are properly sorted
      let start = null;
      let end = null;
      if (dates[0] == dates[1]) {
        start = dates[0];
      } else if (dates[0] > dates[1]) {
        start = dates[1];
        end = dates[0];
      } else {
        start = dates[0];
        end = dates[1];
      }
      let dateRange = null;
      if (end) {
        dateRange = `${start.replace(/-+/g, '')}to${end.replace(/-+/g, '')}`;
      } else {
        dateRange = start.replace(/-+/g, '');
      }
      this.loading = true;
      getActivity(dateRange, this.orgName)
        .then((results) => {
          let userList = [];
          if (results.clients[this.orgName]) {
            userList = results.clients[this.orgName].users;
          }
          // Get users and call amounts from API
          const userGroups = {};
          for (let index = 0; index < userList.length; index++) { // eslint-disable-line
            let groupName = '';
            if (userList[index].group) {
              groupName = userList[index].group;
            } else {
              groupName = userList[index].name;
            }
            const groupCalls = userList[index].calls;
            if (!(groupName in userGroups)) {
              userGroups[groupName] = groupCalls;
            } else {
              userGroups[groupName] += groupCalls;
            }
          }
          // Create items array
          const items = Object.keys(userGroups).map(function(key) { // eslint-disable-line
            return [key, userGroups[key]];
          });
          // Sort the array based on the second element
          items.sort(function(first, second) { // eslint-disable-line
            return second[1] - first[1];
          });
          // Get back to readable object for setting data
          const userDict = {};
          for (const x in Object.values(items)) { // eslint-disable-line
            const [a, b] = [Object.values(items)[x][0], Object.values(items)[x][1]];
            userDict[a] = b;
          }
          // Set data
          this.chartOptions.xaxis.categories = Object.keys(userDict);
          this.series[0].data = Object.values(userDict);
          this.loading = false;
        })
        .catch((error) => {
          console.log(error); // eslint-disable-line
          this.error = true;
          this.message = error.message;
        });
    },
  },
  mounted() {
    // this.getActivity();
    this.setLastMonth();
  },
  created() {
    const today = new Date();
    const dateStr = `${String(today.getFullYear())}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}`;
    this.date = Array.prototype.slice.call([dateStr]);
  },
  computed: {
    dateRangeText() {
      var dr = Array.prototype.slice.call(this.date);
      return dr.join(' ~ ');
    },
  },
  // watch: {
  //   timeFrame() {
  //     this.getActivity();
  //   },
  // },
};
</script>

<style scoped>
.loading-indicator {
  position: absolute;
  top: 0;
  right: 0;
}
.data-warning {
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
}
</style>
