<template>
  <div class="client-activity-dashboard">
    <!-- <ClientList/> -->
    <ClientActivity/>
  </div>
</template>

<script>
// import ClientList from '@/components/activity/ClientList.vue'
import ClientActivity from '@/components/activity/ClientActivity.vue';

export default {
  name: 'ClientActivityDashboard',
  components: {
    // ClientList,
    ClientActivity,
  },
};
</script>
