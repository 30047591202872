<template>
  <div class="client-activity">
    <div v-if="client != undefined">
      <v-container>
        <v-row>
          <v-col cols="12" class="print-section">
            <div class="pagebreak"> </div>
            <CommitmentProgress :client="client" :accountId="accountId" />
          </v-col>
          <v-col cols="12" class="print-section">
            <div class="pagebreak"> </div>
            <!-- <ActivityByMonth :client="client" :accountId="accountId" /> -->
          </v-col>
          <v-col cols="12" class="print-section">
            <div class="pagebreak"> </div>
            <CurrentMonthlyActivity :client="client" :accountId="accountId" />
          </v-col>
          <v-col cols="6" md="6" class="print-section">
            <div class="pagebreak"> </div>
            <GeographicDistribution :client="client" :accountId="accountId" />
          </v-col>
          <v-col cols="6" md="6" class="print-section">
            <div class="pagebreak"> </div>
            <IndustrySegments :client="client" :accountId="accountId" />
          </v-col>
          <v-col cols="12" class="print-section">
            <div class="pagebreak"> </div>
            <UserCalls :client="client" :accountId="accountId" />
          </v-col>
          <v-col cols="12">
            <div class="pagebreak"> </div>
            <UserCallsThisCycle :client="client" />
          </v-col>
        </v-row>
      </v-container>
    </div>
    <v-card v-if="client == undefined">
      <v-card-text><strong>Error:</strong> No client found.</v-card-text>
    </v-card>
  </div>
</template>

<script>
import CommitmentProgress from './CommitmentProgress.vue';
// import ActivityByMonth from './ActivityByMonth.vue';
import CurrentMonthlyActivity from './CurrentMonthlyActivity.vue';
import GeographicDistribution from './GeographicDistribution.vue';
import IndustrySegments from './IndustrySegments.vue';
import UserCalls from './UserCalls.vue';
import UserCallsThisCycle from './UserCallsThisCycle.vue';

export default {
  name: 'ClientActivity',
  data() {
    return {
      client: this.$store.state.client,
      accountId: this.$store.state.accountId,
    };
  },
  components: {
    CommitmentProgress,
    // ActivityByMonth,
    CurrentMonthlyActivity,
    GeographicDistribution,
    IndustrySegments,
    UserCalls,
    UserCallsThisCycle,
  },
};
</script>
