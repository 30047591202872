<template>
  <div class="activity">
    <v-container class="move-up">
      <v-row>
        <v-col>
          <v-breadcrumbs
            dark
            :items="items"
          ></v-breadcrumbs>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3" lg="2">
          <v-sheet
            elevation="6"
            height="150"
            width="150"
            rounded
            class="pa-2 client-logo"
          >
            <v-img
              :src="require('@/assets/clients/' + this.$store.state.client + '_logo.png')"
              :alt="this.$store.state.client"
              width='150'
            ></v-img>
          </v-sheet>
        </v-col>
        <v-col cols="12" md="7" lg="6">
          <h2 class="display-1 mb-2">{{ this.$store.state.client }} Activity Dashboard</h2>
          <p class="subtitle-1">
            FRISS Underwriting Insights usage analytics for your business.
          </p>
          <v-btn
            @click="printReport()"
          >
            <v-icon class="mr-2">mdi-printer</v-icon> Print Report
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <div class="custom-backgroud"></div>

    <ClientActivityDashboard />
  </div>
</template>

<script>
// @ is an alias to /src
import ClientActivityDashboard from '@/components/ClientActivityDashboard.vue';

export default {
  name: 'Activity',
  metaInfo: {
    title: 'Activity',
  },
  data: () => ({
    items: [
      {
        text: 'AppHub',
        exact: true,
        to: '/',
      },
      {
        text: 'Activity',
        exact: true,
        to: '/activity',
      },
    ],
  }),
  components: {
    ClientActivityDashboard,
  },
  methods: {
    printReport() {
      window.print();
    },
  },
};
</script>

<style lang="scss">
.custom-backgroud {
  width: 100%;
  min-height: 600px;
  // background: #1d262f;
  background: linear-gradient(155.26deg,
  #1d262f,#241568 28.82%,#12509e 60.55%,#34b1c7 82.92%,#9de0d5);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}
@media print {
  @page {
    size: A4 landscape;
    margin: 1rem;
  }
  * {
    color: #333;
  }
  body {
    // margin: 0;
    overflow: auto;
    height: auto;
  }
  .v-card.v-sheet.theme--light {
    float: none;
    display: block!important;
    clear: both!important;
    page-break-after: always!important;
    box-shadow: none!important;
    break-before: page!important;
  }
  .v-btn, .v-app-bar, .v-breadcrumbs, ._hj_feedback_container {
    display: none!important;
  }
  .v-image__image {
    -webkit-print-color-adjust: exact!important;
  }
  .v-main {
    padding: 0!important;
  }
  .print-section {
    // max-height: 250mm;
    overflow: hidden;
    // border: 2px solid #333!important;
    // border-radius: 10px!important;
    // break-inside: avoid!important;
    // page-break-inside: avoid!important;
  }
  .pagebreak {
    clear: both;
    page-break-before: always;
  }
}

@media(min-width:1200px) {
  .custom-backgroud {
    min-height: 400px;
  }
}

.move-up {
  position: relative;
  z-index: 1;
  color: #fff;
}

.client-logo {
  display: flex;
  align-items: center;
}
</style>
