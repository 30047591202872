import { API } from 'aws-amplify';
// import api from './api'; // Legacy api call

const getActivity = (timeFrame, orgName) => {
  const activityData = {
    body: {
      when: timeFrame,
      who: orgName,
    },
    headers: {
      // 'Access-Control-Allow-Origin': '*',
      // 'Access-Control-Allow-Credentials': true,
    },
  };

  return API.post('AppHubAPI', 'activity', activityData)
    .then(async (response) => response);

  // Legacy api call
  // api
  //   .get(`activity/${timeFrame}/${orgName}`)
  //   .then(async (response) => response)
};

export default getActivity;
