import { API } from 'aws-amplify';

const getCommitment = (orgName) => {
  const commitmentData = {
    body: {
      when: '19000901', // timeFrame  -  Allways pulls from 1900, once this gets moved somewhere else we can remove
      who: orgName,
    },
  };

  return API.post('AppHubAPI', 'activity/fiscal', commitmentData)
    .then((response) => response);
};

export default getCommitment;
