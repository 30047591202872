<template>
  <div>
    <v-card>
      <v-container class="text-center">
        <h2 class="title font-weight-light text-left">
          Usage for
          {{ today | moment('MMMM YYYY') }}
        </h2>
        <p class="caption text-left mb-4">Your daily view of actvity</p>

        <v-container>
          <v-row>
            <v-col>
              <small class="caption grey--text">Total</small>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    v-on="on"
                  ><v-icon small color="blue darken-2">mdi-information-outline</v-icon></span>
                </template>
                <span>Total API calls this month.</span>
              </v-tooltip>
              <div class="display-1 font-weight-light" v-if="!loading">
                {{ calls | numFormat }}
              </div>
            </v-col>
             <v-col>
              <small class="caption grey--text">Profile Calls</small>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    v-on="on"
                  ><v-icon small color="blue darken-2">mdi-information-outline</v-icon></span>
                </template>
                <span>Total profile calls this month.</span>
              </v-tooltip>
              <div class="display-1 font-weight-light" v-if="!loading">
                {{ this.profileCalls | numFormat }}
              </div>
            </v-col>
            <v-col>
              <small class="caption grey--text">Appetite Calls</small>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    v-on="on"
                  ><v-icon small color="blue darken-2">mdi-information-outline</v-icon></span>
                </template>
                <span>Total appetite calls this month.</span>
              </v-tooltip>
              <div class="display-1 font-weight-light" v-if="!loading">
                {{ this.appetiteCalls | numFormat }}
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-container>

      <apexchart
        v-if="!loading"
        height="350"
        :options="chartOptions"
        :series="series"
      ></apexchart>

      <v-progress-linear
        v-if="loading"
        class="loading-indicator"
        indeterminate
        color="primary"
      ></v-progress-linear>

      <v-card-text class="pt-0">
          <v-divider class="my-2"></v-divider>
          <v-icon class="mr-2" small>mdi-clock</v-icon>
          <span class="caption grey--text font-weight-light">
            Showing activity as of {{ Date().toLocaleString() | moment("dddd, MMMM Do YYYY") }}
          </span>
      </v-card-text>
    </v-card>
    <v-snackbar v-model="error">
      <v-icon>mdi-alert-circle-outline</v-icon>
      {{ message }}
    </v-snackbar>
  </div>
</template>

<script>
import getActivity from '@/services/getActivity';

export default {
  name: 'Monthly',
  props: {
    client: {
      type: String,
    },
  },
  data() {
    return {
      orgName: this.client,
      today: '',
      totalValues: [],
      appetiteValues: [],
      profileValues: [],
      dates: [],
      dateValues: [],
      calls: 0,
      appetiteCalls: 0,
      profileCalls: 0,
      loading: true,
      error: false,
      message: '',
      series: [{
        name: 'Calls',
        data: [],
      }],
      chartOptions: {
        chart: {
          toolbar: {
            show: false,
          },
          height: 350,
          type: 'line',
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'smooth',
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'],
            opacity: 0.5,
          },
        },
        xaxis: {
          categories: [],
        },
        noData: {
          text: 'No data to display for this period.',
          align: 'center',
          verticalAlign: 'middle',
          offsetX: 0,
          offsetY: 0,
          style: {
            color: '#333',
            fontSize: '14px',
            fontFamily: 'Libre Franklin, sans-serif',
          },
        },
      },
    };
  },
  methods: {
    getActivity(d) {
      getActivity(d, this.orgName)
        .then((results) => {
          let totalCalls = 0;
          let appetiteCalls = 0;
          let profileCalls = 0;
          if (results.clients[this.orgName]) {
            totalCalls = results.clients[this.orgName].calls;
            appetiteCalls = results.clients[this.orgName].appetite;
            profileCalls = totalCalls - appetiteCalls;
          }
          this.calls += totalCalls;
          this.appetiteCalls += appetiteCalls;
          this.profileCalls += profileCalls;
          this.totalValues.push(totalCalls);
          this.appetiteValues.push(appetiteCalls);
          this.profileValues.push(profileCalls);
          if (this.profileValues.length === parseInt(this.dateValues[this.dateValues.length - 1].slice(-2), 10)) { // eslint-disable-line
            this.chartOptions.xaxis = { categories: this.dates };
            this.series = [{ name: 'Total Calls', data: this.totalValues }, { name: 'Profile Calls', data: this.profileValues }, { name: 'Appetite Calls', data: this.appetiteValues }];
            this.loading = false;
          }
        })
        .catch((error) => {
          console.log(error); // eslint-disable-line
          this.error = true;
          this.message = error.message;
        });
    },
  },
  mounted() {
    this.loading = true;
    const today = new Date();
    this.today = today;
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
    const yyyy = today.getFullYear();
    // Get formatted strings for all dates within the month
    for (let index = 1; index < Number(dd) + 1; index++) { // eslint-disable-line
      let formatIndex = 0;
      if (index.toString().length !== 2) {
        formatIndex = `0${index}`;
      } else {
        formatIndex = index;
      }
      // Format the date and push to X axis
      this.dateValues.push(yyyy + mm + formatIndex);
      this.dates.push(`${mm}/${formatIndex}`);
    }
    // Call API using generated date ranges
    for (let d in this.dateValues) { // eslint-disable-line
      this.getActivity(this.dateValues[d]);
    }
  },
};
</script>

<style scoped>
.loading-indicator {
  position: absolute;
  top: 0;
  right: 0;
}
.data-warning {
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
}
</style>
