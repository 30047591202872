<template>
  <div>
    <v-card>
      <v-container class="text-center">
        <h2 class="title text-left font-weight-light pt-2">Geographic Distribution</h2>
        <p class="caption text-left">
          Insights into the geographic distributions based on activity hits
        </p>

        <v-btn-toggle
          mandatory
          dense
          borderless
          color="primary"
          v-model="timeFrame"
        >
          <v-btn value="today">
            Today
          </v-btn>
          <v-btn value="yesterday">
            Yesterday
          </v-btn>
          <v-btn value="last week">
            Last Week
          </v-btn>
          <v-btn value="last month">
            Last Month
          </v-btn>
          <v-btn value="this month">
            This Month
          </v-btn>
        </v-btn-toggle>
      </v-container>

      <div class="examples__block__map examples__block__map--usa">
        <svg-map
          :map="UnitedStates"
          :location-class="getLocationClass"
          @mouseover="pointLocation"
          @mouseout="unpointLocation"
          @mousemove="moveOnLocation"
        />

        <v-tooltip
          v-if="!states.length"
          top>
          <template v-slot:activator="{ on, attrs }">
            <span
              class="data-warning"
              v-bind="attrs"
              v-on="on"
            ><v-icon>mdi-alert-circle-outline</v-icon></span>
          </template>
          <span>No data to display for this period.</span>
        </v-tooltip>

        <v-progress-linear
          v-if="loading"
          class="loading-indicator"
          indeterminate
          color="primary"
        ></v-progress-linear>

        <div
          class="examples__block__map__tooltip"
          :style="tooltipStyle"
        >
          {{ pointedLocation }}: {{ pointedValue }}
        </div>
      </div>
      <v-card-text class="pt-0 text-left">
        <v-divider class="my-2"></v-divider>
        <v-icon
        class="mr-2"
        small
        >
        mdi-clock
        </v-icon>
        <span class="caption grey--text font-weight-light">
          Showing activity for
          {{ date.from | moment("dddd, MMMM Do YYYY") }}
          to {{ date.to | moment("dddd, MMMM Do YYYY") }}
        </span>
      </v-card-text>
    </v-card>
    <v-snackbar v-model="error">
      <v-icon>mdi-alert-circle-outline</v-icon>
      {{ message }}
    </v-snackbar>
  </div>
</template>

<script>
import { SvgMap } from 'vue-svg-map';
import UnitedStates from '@svg-maps/usa';
import 'vue-svg-map/dist/index.css';

import getActivity from '@/services/getActivity';

export default {
  name: 'GeographicDistribution',
  components: {
    SvgMap,
  },
  props: {
    client: {
      type: String,
    },
  },
  data() {
    return {
      UnitedStates,
      loading: true,
      error: false,
      message: '',
      pointedLocation: null,
      pointedValue: null,
      tooltipStyle: 'display: none',
      states: [],
      stateVals: [],
      date: [],
      timeFrame: 'last month',
      orgName: this.client,
    };
  },
  methods: {
    getActivity() {
      this.loading = true;
      getActivity(this.timeFrame, this.orgName)
        .then((results) => {
          this.states = [];
          this.stateVals = [];
          if (results.clients[this.orgName]) {
            this.states = Object.keys(results.clients[this.orgName].geo).map((v) => v.toLowerCase()); // eslint-disable-line
            this.stateVals = Object.values(results.clients[this.orgName].geo);
          }
          this.date = results.date;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error); // eslint-disable-line
          this.error = true;
          this.message = error.message;
        });
    },
    pointLocation(event) {
      this.pointedLocation = event.target && event.target.attributes.name.value;
      const stateIndex = this.states.indexOf(event.target.id);
      const stateVal = this.stateVals[stateIndex];
      if (stateVal != null) {
        this.pointedValue = stateVal;
      } else {
        this.pointedValue = 0;
      }
    },
    unpointLocation() {
      this.pointedLocation = null;
      this.tooltipStyle = { display: 'none' };
    },
    moveOnLocation(event) {
      this.tooltipStyle = {
        display: 'block',
        top: `${event.clientY + 10}px`,
        left: `${event.clientX - 100}px`,
      };
    },
    getLocationClass(location) {
      // Generate heat map
      if (this.states.includes(location.id)) {
        const stateIndex = this.states.indexOf(location.id);
        const stateVal = this.stateVals[stateIndex];
        const total = this.stateVals.reduce((a, b) => a + b, 0);
        if ((stateVal / total) * 100 < 5) {
          return `svg-map__location svg-map__location--heat${1}`;
        }
        if ((stateVal / total) * 100 < 10) {
          return `svg-map__location svg-map__location--heat${2}`;
        }
        return `svg-map__location svg-map__location--heat${3}`;
      }
      return `svg-map__location svg-map__location--heat${0}`;
    },
  },
  mounted() {
    this.getActivity();
  },
  watch: {
    timeFrame() {
      this.getActivity();
    },
  },
};
</script>

<style scoped>
.loading-indicator {
  position: absolute;
  top: 0;
  right: 0;
}
.data-warning {
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
}
</style>
