var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.activeCommitmentStr != 'None')?_c('v-card',[_c('v-container',[_c('v-row',[_c('v-col',[_c('h2',{staticClass:"title font-weight-light pt-2"},[_vm._v("Group Activity")]),_c('p',{staticClass:"caption text-left mb-4"},[_vm._v("Total user activity over contract cycle")])]),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","md":"3"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.date,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"prepend-icon":"mdi-calendar","append-icon":"mdi-menu-down","dense":"","readonly":""},model:{value:(_vm.dateRangeText),callback:function ($$v) {_vm.dateRangeText=$$v},expression:"dateRangeText"}},'v-text-field',attrs,false),on))]}}],null,false,50628896),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"type":"month","multiple":"","no-title":"","scrollable":"","range":""},model:{value:(_vm.months),callback:function ($$v) {_vm.months=$$v},expression:"months"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.$refs.menu.save(_vm.date), _vm.getActivity()}}},[_vm._v(" OK ")])],1)],1),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Dropdown ")])]}}],null,false,519162969)},[_c('v-list',_vm._l((_vm.items),function(item,index){return _c('v-list-item',{key:index},[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)}),1)],1)],1)],1),_c('v-container',[_c('v-row',[_c('v-col',[_c('small',{staticClass:"caption grey--text"},[_vm._v("Cycle Period")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":"blue darken-2"}},[_vm._v("mdi-information-outline")])],1)]}}],null,false,3496875591)},[_c('span',[_vm._v("The period of your contract cycle.")])]),_c('div',{staticClass:"display-1 font-weight-light"},[_vm._v(_vm._s(_vm.activeCommitmentStr))])],1)],1)],1)],1),_c('apexchart',{attrs:{"height":"600","options":_vm.chartOptions,"series":_vm.series}}),(_vm.loading)?_c('v-progress-linear',{staticClass:"loading-indicator",attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),_c('v-card-text',{staticClass:"pt-0 text-left"},[_c('v-divider',{staticClass:"my-2"}),_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-clock")]),_c('span',{staticClass:"caption grey--text font-weight-light"},[_vm._v(" Showing activity as of "+_vm._s(_vm._f("moment")(Date().toLocaleString(),"dddd, MMMM Do YYYY"))+" ")])],1)],1):_vm._e(),_c('v-snackbar',{model:{value:(_vm.error),callback:function ($$v) {_vm.error=$$v},expression:"error"}},[_c('v-icon',[_vm._v("mdi-alert-circle-outline")]),_vm._v(" "+_vm._s(_vm.message)+" ")],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }