<template>
  <div>
    <v-card v-if="activeCommitmentStr != 'None'">
      <v-container>
        <v-row>
          <v-col>
            <h2 class="title font-weight-light pt-2">Group Activity</h2>
            <p class="caption text-left mb-4">Total user activity over contract cycle</p>
          </v-col>
          <v-col cols="12" md="3" class="text-right">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="date"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateRangeText"
                  prepend-icon="mdi-calendar"
                  append-icon="mdi-menu-down"
                  dense
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="months"
                type="month"
                multiple
                no-title
                scrollable
                range
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="menu = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menu.save(date), getActivity()"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>

            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                  Dropdown
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(item, index) in items"
                  :key="index"
                >
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <!-- <v-btn-toggle
              v-model="toggle_exclusive"
              dense
              borderless
              color="primary"
              class="mb-6"
            >
              <v-btn value="today" v-on:click="setToday">
                2nd QTR 2021
              </v-btn>
              <v-btn value="lastWeek" v-on:click="setLastWeek">
                3rd QTR 2021
              </v-btn>
              <v-btn value="lastWeek" v-on:click="setLastWeek">
                4th QTR 2021
              </v-btn>
              <v-btn value="lastWeek" v-on:click="setLastWeek">
                1st QTR 2022
              </v-btn>
              <v-btn value="lastWeek" v-on:click="setLastWeek">
                This Cycle
              </v-btn>
              <v-btn value="lastMonth" v-on:click="setLastMonth">
                Last Month
              </v-btn>
            </v-btn-toggle> -->
          </v-col>
        </v-row>

        <v-container>
          <v-row>
            <v-col>
              <small class="caption grey--text">Cycle Period</small>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    v-on="on"
                  ><v-icon small color="blue darken-2">mdi-information-outline</v-icon></span>
                </template>
                <span>The period of your contract cycle.</span>
              </v-tooltip>
              <div class="display-1 font-weight-light">{{ activeCommitmentStr }}</div>
            </v-col>
          </v-row>
        </v-container>
      </v-container>

      <!-- height="350"  -->
      <apexchart
        height="600"
        :options="chartOptions"
        :series="series"
      ></apexchart>

      <v-progress-linear
        v-if="loading"
        class="loading-indicator"
        indeterminate
        color="primary"
      ></v-progress-linear>

      <v-card-text class="pt-0 text-left">
        <v-divider class="my-2"></v-divider>
        <v-icon class="mr-2" small>mdi-clock</v-icon>
        <span class="caption grey--text font-weight-light">
          Showing activity as of {{ Date().toLocaleString() | moment("dddd, MMMM Do YYYY") }}
        </span>
      </v-card-text>
    </v-card>
    <v-snackbar v-model="error">
      <v-icon>mdi-alert-circle-outline</v-icon>
      {{ message }}
    </v-snackbar>
  </div>
</template>

<script>
import getFiscalAthena from '@/services/getFiscalAthena';
import getCommitment from '@/services/getCommitment';
// import getActivity from '@/services/getActivity';
import moment from 'moment';

export default {
  name: 'UserCallsThisCycle',
  props: {
    client: {
      type: String,
    },
  },
  data() {
    return {
      items: [
        { title: '1st QTR 2021' },
        { title: '2nd QTR 2021' },
        { title: '3rd QTR 2021' },
        { title: '4th QTR 2021' },
        { title: 'Last Month' },
        { title: 'This Cycle' },
        { title: 'Select Month' },
      ],
      orgName: this.client,
      timeFrame: 'today',
      activeCommitmentStart: '2021-02',
      channels: [],
      loading: false,
      error: false,
      message: '',
      activeCommitment: 'today',
      activeCommitmentStr: '',
      series: [{
        name: 'Profile Calls',
        data: [],
      }, {
        name: 'Profile Hits',
        data: [],
      }],
      chartOptions: {
        chart: {
          toolbar: {
            show: false,
          },
          stacked: true,
          type: 'bar',
          // height: '1200',
        },
        plotOptions: {
          bar: {
            horizontal: false,
            dataLabels: {
              position: 'top',
            },
          },
        },
        dataLabels: {
          enabled: false,
          offsetX: 0,
          style: {
            fontSize: '12px',
            colors: ['#000'],
          },
        },
        stroke: {
          show: true,
          width: 1,
          colors: ['#fff'],
        },
        xaxis: {
          categories: [],
        },
        noData: {
          text: 'No data to display for this period.',
          align: 'center',
          verticalAlign: 'middle',
          offsetX: 0,
          offsetY: 0,
          style: {
            color: '#333',
            fontSize: '14px',
            fontFamily: 'Libre Franklin, sans-serif',
          },
        },
      },
    };
  },
  methods: {
    getActiveCommitment() {
      this.loading = true;
      getCommitment(this.orgName)
        .then((results) => {
          const today = new Date();
          let currentCommitmentStart = 0;
          let currentCommitmentEnd = 0;
          let fromStr = '';
          let toStr = '';
          if (results[this.orgName].commitments) {
            const commitments = results[this.orgName].commitments; // eslint-disable-line
            for (let index = 0; index < commitments.length; index++) { // eslint-disable-line
              const dateFrom = commitments[index].start;
              const dateTo = commitments[index].end;
              const d1 = dateFrom.split('-');
              const d2 = dateTo.split('-');

              const from = new Date(d1[0], parseInt(d1[1], 10) - 1, d1[2]);
              const to = new Date(d2[0], parseInt(d2[1], 10) - 1, d2[2]);
              fromStr = from.toLocaleDateString('en-US');
              toStr = to.toLocaleDateString('en-US');

              // Check if commitment is active
              if (today > from && today < to) {
                currentCommitmentStart = d1[0] + d1[1] + d1[2];
                currentCommitmentEnd = d2[0] + d2[1] + d2[2];
                break;
              }
            }
          }
          // Get data if active commitment is found, else process is complete
          if (currentCommitmentEnd !== 0) {
            this.activeCommitment = currentCommitmentStart + 'to' + currentCommitmentEnd; // eslint-disable-line
            this.activeCommitmentStr = fromStr + ' - ' + toStr; // eslint-disable-line
            this.activeCommitmentStart = moment(fromStr).format('YYYY-MM');
            this.getActivity();
          } else {
            this.activeCommitmentStr = 'None';
            this.loading = false;
          }
        })
        .catch((error) => {
          console.log(error); // eslint-disable-line
          this.error = true;
          this.message = error.message;
        });
    },
    getActivity() {
      this.loading = true;
      getFiscalAthena('user_activity_by_client_and_month', this.activeCommitmentStart, this.orgName) // this.activeCommitment
        .then((results) => {
          const stats = {
            activeUsers: [],
            profileCalls: [],
            profileHits: [],
            appetiteCalls: [],
            appetiteHits: [],
          };

          let newProfileCalls = 0;

          // Iterate over results
          results.forEach((result) => {
            if (stats.activeUsers.includes(result.user_name)) {
              // newProfileCalls += parseInt(result.profile, 10) || 0;
              // stats.profileCalls.push(newProfileCalls);

              // stats.profileHits.push(parseInt(result.profile_hits, 10) || 0);
              // stats.appetiteCalls.push(parseInt(result.appetite, 10) || 0);
              // stats.appetiteHits.push(parseInt(result.appetite_hits, 10) || 0);
            } else {
              stats.activeUsers.push(result.user_name);

              newProfileCalls = parseInt(result.profile, 10) || 0;
              stats.profileCalls.push(newProfileCalls);

              stats.profileHits.push(parseInt(result.profile_hits, 10) || 0);
              stats.appetiteCalls.push(parseInt(result.appetite, 10) || 0);
              stats.appetiteHits.push(parseInt(result.appetite_hits, 10) || 0);
            }
          });

          // Sort results
          // keysSorted = Object.keys(stats).sort(function(a,b){return stats[a]-stats[b]})

          // Push values into Apexcharts
          // Users
          this.chartOptions = { xaxis: { categories: stats.activeUsers } };

          // Profile
          this.series[0].data = stats.profileCalls;
          this.series[1].data = stats.profileHits;

          // Appetite
          if (stats.appetiteCalls.reduce((a, b) => a + b, 0) > 0) {
            this.series[2] = { name: 'Appetite Calls', data: stats.appetiteCalls };
            this.series[3] = { name: 'Appetite Hits', data: stats.appetiteHits };
          }
          this.loading = false;
        })
        .catch((error) => {
          console.log(error); // eslint-disable-line
          this.error = true;
          this.message = error.message;
        });
    },
  },
  mounted() {
    this.getActiveCommitment();
  },
};
</script>

<style scoped>
.loading-indicator {
  position: absolute;
  top: 0;
  right: 0;
}
.data-warning {
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
}
</style>
